<!-- AI绘画 作品集 -->

<template>
	<div>
		<div class="profile-nav-bg" :style="{ backgroundImage: `url(${require('@/assets/imgs/bg-profile.jpg')})`, height: '200px' }"></div>
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<a-avatar :size="74" shape="square" :src="userInfo.avatar" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{ userInfo.nickname }}</h4>
							<div>
								<p>欢迎来到您的作品集!</p>
							</div>
						</div>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
						<a-radio-group v-model="tabsId" size="small">
							<a-radio-button :value="item.id" v-for="(item, index) in tabsList" :key="'tab_' + item.id">{{ item.title }}</a-radio-button>
						</a-radio-group>
					</a-col>
				</a-row>
			</template>
		</a-card>

		<div v-if="tabsId === 1">
			<div class="atlas_box" v-if="atlas.data.length">
				<div class="list_box">
					<div class="list">
						<waterfall :col="4" :gutterWidth="20" :data="atlas.data">
							<template>
								<div class="item" v-if="item.id" v-for="(item, index) in atlas.data" :key="'atlas_img_' + index" @click="pictureHandle('view', item)">
									<div class="img">
										<img :src="item.image" alt="" />
										<div class="coverage">
											<div class="c-line-2">{{ item.desc }}</div>
											<div class="time">{{ item.createtime }}</div>
											<div class="tools">
												<div class="icon" @click.stop="pictureHandle('vote', item)">
													<a-icon type="heart" :theme="item.is_vote == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_vote == 1 ? '#FF3434' : '#c4d9f3' }" />
												</div>
												<div class="icon" @click.stop="pictureHandle('like', item)">
													<a-icon type="like" :theme="item.is_like == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_like == 1 ? '#FF3434' : '#c4d9f3' }" />
												</div>
												<div class="icon" @click.stop="deletePicture(item.id)">
													<a-icon type="delete" :style="{ fontSize: '16px', color: '#c4d9f3' }" />
												</div>
											</div>
										</div>
									</div>
									<div class="infos" v-if="item.image">
										<div class="right">
											<div class="icons">
												<a-icon type="heart" />
												<span>{{ item.votes }}</span>
											</div>
											<div class="icons">
												<a-icon type="like" />
												<span>{{ item.likes }}</span>
											</div>
											<div class="icons">
												<a-icon type="eye" />
												<span>{{ item.views }}</span>
											</div>
										</div>
									</div>
								</div>
							</template>
						</waterfall>
					</div>
				</div>
			</div>
			<div class="mt-50" v-else>
				<template>
					<a-empty description="暂无数据" />
				</template>
			</div>
		</div>
		<div v-if="tabsId === 2">
			<div class="atlas_box" v-if="collect.data.length">
				<div class="list_box">
					<div class="list">
						<waterfall :col="4" :gutterWidth="20" :data="collect.data">
							<template>
								<div class="item" v-if="item.id" v-for="(item, index) in collect.data" :key="'collect_img_' + index" @click="pictureHandle('view', item)">
									<div class="img">
										<img :src="item.image" alt="" />
										<div class="coverage">
											<div class="c-line-2">{{ item.desc }}</div>
											<div class="time">{{ item.vote_createtime }}</div>
											<div class="tools">
												<div class="icon" @click.stop="pictureHandle('vote', item)">
													<a-icon type="heart" :theme="item.is_vote == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_vote == 1 ? '#FF3434' : '#c4d9f3' }" />
												</div>
												<div class="icon" @click.stop="pictureHandle('like', item)">
													<a-icon type="like" :theme="item.is_like == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_like == 1 ? '#FF3434' : '#c4d9f3' }" />
												</div>
											</div>
										</div>
									</div>
									<div class="infos" v-if="item.image">
										<div class="left">
											<div class="avatar">
												<img :src="item.avatar" alt="" />
											</div>
											<div class="nickname c-line-1">{{ item.nickname }}</div>
										</div>
										<div class="right">
											<div class="icons">
												<a-icon type="heart" />
												<span>{{ item.votes }}</span>
											</div>
											<div class="icons">
												<a-icon type="like" />
												<span>{{ item.likes }}</span>
											</div>
											<div class="icons">
												<a-icon type="eye" />
												<span>{{ item.views }}</span>
											</div>
										</div>
									</div>
								</div>
							</template>
						</waterfall>
					</div>
				</div>
			</div>
			<div class="mt-50" v-else>
				<template>
					<a-empty description="暂无数据" />
				</template>
			</div>
		</div>
		<div v-if="tabsId === 3">
			<div class="atlas_box" v-if="likes.data.length">
				<div class="list_box">
					<div class="list">
						<waterfall :col="4" :gutterWidth="20" :data="likes.data">
							<template>
								<div class="item" v-if="item.id" v-for="(item, index) in likes.data" :key="'likes_img_' + index" @click="pictureHandle('view', item)">
									<div class="img">
										<img :src="item.image" alt="" />
										<div class="coverage">
											<div class="c-line-2">{{ item.desc }}</div>
											<div class="time">{{ item.vote_createtime }}</div>
											<div class="tools">
												<div class="icon" @click.stop="pictureHandle('vote', item)">
													<a-icon type="heart" :theme="item.is_vote == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_vote == 1 ? '#FF3434' : '#c4d9f3' }" />
												</div>
												<div class="icon" @click.stop="pictureHandle('like', item)">
													<a-icon type="like" :theme="item.is_like == 0 ? 'outlined' : 'filled'" :style="{ fontSize: '16px', color: item.is_like == 1 ? '#FF3434' : '#c4d9f3' }" />
												</div>
											</div>
										</div>
									</div>
									<div class="infos" v-if="item.image">
										<div class="left">
											<div class="avatar">
												<img :src="item.avatar" alt="" />
											</div>
											<div class="nickname c-line-1">{{ item.nickname }}</div>
										</div>
										<div class="right">
											<div class="icons">
												<a-icon type="heart" />
												<span>{{ item.votes }}</span>
											</div>
											<div class="icons">
												<a-icon type="like" />
												<span>{{ item.likes }}</span>
											</div>
											<div class="icons">
												<a-icon type="eye" />
												<span>{{ item.views }}</span>
											</div>
										</div>
									</div>
								</div>
							</template>
						</waterfall>
					</div>
				</div>
			</div>
			<div class="mt-50" v-else>
				<template>
					<a-empty description="暂无数据" />
				</template>
			</div>
		</div>
		<PreviewImage :show="previewImageShow" :data="previewImageData" @close="previewImageShow = false" @update="updateCollectStatus"></PreviewImage>
	</div>
</template>

<script>
	import { Debounce } from "@/utils/tools"
	import PreviewImage from "@/components/Custom/PreviewImage.vue"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: { PreviewImage },
		data() {
			return {
				tabsId: 1,
				tabsList: [
					{ id: 1, title: "我的作品" },
					{ id: 2, title: "我的收藏" },
					{ id: 3, title: "我的点赞" }
				],
				atlas: {
					data: [],
					page: 1,
					pagesize: 20
				},
				collect: {
					data: [],
					page: 1,
					pagesize: 20
				},
				likes: {
					data: [],
					page: 1,
					pagesize: 20
				},
				previewImageShow: false,
				previewImageData: {
					id: "",
					desc: "",
					image: "",
					width: "",
					height: "",
					vote: false
				}
			}
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"])
		},
		watch: {
			tabsId(newValue, oldValue) {
				if (newValue == 2 || newValue == 3) {
					this.collect = {
						data: [],
						page: 1,
						pagesize: 20
					}
					this.likes = {
						data: [],
						page: 1,
						pagesize: 20
					}
					this.getCollectList()
				} else if (newValue == 1) {
					this.atlas = {
						data: [],
						page: 1,
						pagesize: 20
					}
					this.getDrawingRecord()
				}
			}
		},
		created() {
			this.getDrawingRecord()
			window.addEventListener("scroll", this.handleScroll, false)
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll, false)
		},
		methods: {
			...mapActions("user", ["getUserInfo"]),
			// 更新收藏状态
			updateCollectStatus(data) {
				if (this.tabsId == 1) {
					this.atlas.data.map(item => {
						if (item.id == data.id) {
							item.is_vote = data.vote
							if (item.is_vote == 0) {
								item.votes = Number(item.votes) - 1
							} else {
								item.votes = Number(item.votes) + 1
							}
						}
					})
				} else if (this.tabsId == 2) {
					this.collect.data = this.collect.data.filter(obj => {
						return obj.id !== data.id
					})
				} else if (this.tabsId == 3) {
					this.likes.data.map(item => {
						if (item.id == data.id) {
							item.is_vote = data.vote
							if (item.is_vote == 0) {
								item.votes = Number(item.votes) - 1
							} else {
								item.votes = Number(item.votes) + 1
							}
						}
					})
				}
			},
			// 删除图片
			deletePicture(id) {
				if (!id) return
				this.$confirm({
					title: "确定要删除当前图片吗？",
					content: "",
					okText: "确定",
					okType: "danger",
					cancelText: "取消",
					onOk: async () => {
						try {
							return await new Promise((resolve, reject) => {
								this.$http("drawing.delete", { id }).then(res => {
									if (res.code === 1) {
										this.atlas.data = this.atlas.data.filter(obj => {
											return obj.id !== id
										})
										this.$message.success(res.msg)
										resolve()
									} else {
										reject(res.msg)
									}
								})
							})
						} catch (error) {
							this.$message.error(error)
						}
					}
				})
			},
			// 图片事件处理
			async pictureHandle(type, item) {
				if (!item.id) return
				const result = await this.$http("drawing.collect", { draw_images_id: item.id, type }, false)
				if (type === "view") {
					this.lookImage(item.id, item.image, item.width, item.height, item.desc, item.is_vote)
					if (result.code === 1) {
						item.views = Number(item.views) + 1
					}
				} else if (type === "vote") {
					if (result.code === 1) {
						item.is_vote = item.is_vote == 0 ? 1 : 0
						if (this.tabsId == 2) {
							this.collect.data = this.collect.data.filter(obj => {
								return obj.id !== item.id
							})
						} else {
							if (item.is_vote == 0) {
								item.votes = Number(item.votes) - 1
							} else {
								item.votes = Number(item.votes) + 1
							}
						}
					}
				} else if (type === "like") {
					if (result.code === 1) {
						item.is_like = item.is_like == 0 ? 1 : 0
						if (this.tabsId == 3) {
							this.likes.data = this.likes.data.filter(obj => {
								return obj.id !== item.id
							})
						} else {
							if (item.is_like == 0) {
								item.likes = Number(item.likes) - 1
							} else {
								item.likes = Number(item.likes) + 1
							}
						}
					}
				}
			},
			// 滚动事件
			handleScroll: Debounce(
				function () {
					let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
					let clientHeight = document.documentElement.clientHeight
					let scrollHeight = document.documentElement.scrollHeight
					if (scrollTop + clientHeight >= scrollHeight - 50) {
						if (this.tabsId === 1) {
							this.atlas.page++
							this.getDrawingRecord()
						} else if (this.tabsId === 2) {
							this.collect.page++
							this.getCollectList()
						} else if (this.tabsId === 3) {
							this.likes.page++
							this.getCollectList()
						}
					}
				},
				20,
				false
			),
			// 查看图片
			lookImage(id, img, w, h, desc, vote) {
				this.previewImageData = {
					id,
					desc,
					image: img,
					width: w,
					height: h,
					vote
				}
				this.previewImageShow = true
			},
			// 获取绘画记录
			getDrawingRecord() {
				this.$http("drawing.record", { page: this.atlas.page, pagesize: this.atlas.pagesize }).then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							this.atlas.data = this.atlas.data.concat(res.data)
						} else {
							this.atlas.page--
						}
					}
				})
			},
			// 获取收藏点赞列表
			getCollectList() {
				let type = "",
					str = ""
				if (this.tabsId == 2) {
					type = "vote"
					str = "collect"
				} else if (this.tabsId == 3) {
					type = "like"
					str = "likes"
				} else {
					return
				}

				this.$http("drawing.collectList", {
					type,
					page: this[str].page,
					pagesize: this[str].pagesize
				}).then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							this[str].data = this[str].data.concat(res.data)
						} else {
							this[str].page--
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.atlas_box {
		width: 100%;
		margin-bottom: 20px;

		.list_box {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			.list {
				::v-deep .vue-waterfall-column {
					width: calc((100% - 60px) / 4);
				}

				.item {
					margin-bottom: 20px;

					.img {
						position: relative;
						border-radius: 8px;
						overflow: hidden;
						cursor: pointer;

						&:hover {
							.coverage {
								display: flex;
							}
						}

						img {
							width: 100%;
							height: auto;
							border-radius: 8px;
						}

						.coverage {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background: rgba($color: #000000, $alpha: 0.3);
							display: none;
							flex-direction: column;
							justify-content: flex-end;
							padding: 10px 10px;
							font-size: 12px;
							color: #fff;

							.time {
								color: #a5adb7;
								margin-top: 8px;
							}

							.tools {
								position: absolute;
								top: 10px;
								right: 10px;
								display: flex;
								align-items: center;

								.icon {
									width: 30px;
									height: 30px;
									border-radius: 8px;
									background: rgba($color: #000000, $alpha: 0.4);
									margin-left: 10px;
									display: flex;
									align-items: center;
									justify-content: center;

									&:hover {
										background: rgba($color: #000000, $alpha: 0.6);
									}

									&:first-child {
										margin-left: 0;
									}
								}
							}
						}
					}

					.infos {
						margin-top: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						color: #838fa2;
						font-size: 12px;

						.left {
							display: flex;
							align-items: center;
							.avatar {
								width: 22px;
								height: 22px;
								border-radius: 50%;
								overflow: hidden;
								border: 1px solid #fff;
								margin-right: 8px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}

							.nickname {
								max-width: 100px;
							}
						}

						.right {
							display: flex;
							align-items: center;

							.icons {
								display: flex;
								align-items: center;
								margin-left: 8px;

								span {
									margin-left: 2px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
